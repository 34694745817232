import React from "react";
// @ts-expect-error
import Barcode from "@components/BarCode";
import Draggable from "react-draggable";

export default function BarcodeWidget(props) {
  const { config = {}, pricetag = {}, empresa = {}, zoom = {}, product = {}, bordered = true } = props;
  return config ? (
    <div
      className="box"
      style={{
        height: `${pricetag.height}cm`,
        width: `${pricetag.width}cm`,
        position: "relative",
        overflow: "hidden",
        padding: "0",
        border: bordered ? "1px solid #999" : "",
        transform: `scale(${zoom || 1})`,
        transformOrigin: "0% 0% 0px",
        '-webkit-print-color-adjust': "exact !important",
      }}
    >
      <Draggable
        position={{ x: config.companyname.x, y: config.companyname.y }}
        onStart={() => false}
      >
        <div
          name="companyname"
          className={{
            box: true,
            "apply-font-companyname": true,
          }}
          style={{
            fontSize: `${config.companyname.font.size}px`,
            color: config.companyname.font.color,
            fontWeight: config.companyname.font.bold ? "bold" : "normal",
            fontStyle: config.companyname.font.italic ? "italic" : "normal",
            visibility: config.companyname.show ? "" : "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          {empresa.fullName || empresa.name}
        </div>
      </Draggable>
      <Draggable
        position={{ x: config.description.x, y: config.description.y }}
        onStart={() => false}
      >
        <div
          name="description"
          className={{
            box: true,
            "apply-font-description": true,
          }}
          style={{
            fontSize: `${config.description.font.size}px`,
            color: config.description.font.color,
            fontWeight: config.description.font.bold ? "bold" : "normal",
            fontStyle: config.description.font.italic ? "italic" : "normal",
            visibility: config.description.show ? "" : "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          {product.nome || config.description.example || "Produto Exemplo"}
        </div>
      </Draggable>
      <Draggable
        position={{ x: config.barcode.x, y: config.barcode.y }}
        onStart={() => false}
      >
        <div
          name="barcode"
          className={{
            box: true,
          }}
          style={{
            visibility: config.barcode.show ? "" : "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Barcode
            value={product.barcode || config.barcode.example || "1234567890123456"}
            width={config.barcode.width}
            height={config.barcode.height}
            font={config.barcode.font}
            lineColor={config.barcode.font.color}
            fontSize={config.barcode.font.size}
            displayValue={config.barcode.textShow}
          />
        </div>
      </Draggable>
      <Draggable
        position={{ x: config.productid.x, y: config.productid.y }}
        onStart={() => false}
      >
        <div
          name="productid"
          className={{
            box: true,
            "apply-font-productid": true,
          }}
          style={{
            fontSize: `${config.productid.font.size}px`,
            color: config.productid.font.color,
            fontWeight: config.productid.font.bold ? "bold" : "normal",
            fontStyle: config.productid.font.italic ? "italic" : "normal",
            visibility: config.productid.show ? "" : "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          {product.idproduct || config.productid.example || "00000001"}
        </div>
      </Draggable>
      <Draggable
        position={{ x: config.price.x, y: config.price.y }}
        onStart={() => false}
      >
        <div
          name="price"
          className={{
            box: true,
            "apply-font-price": true,
          }}
          style={{
            fontSize: `${config.price.font.size}px`,
            color: config.price.font.color,
            fontWeight: config.price.font.bold ? "bold" : "normal",
            fontStyle: config.price.font.italic ? "italic" : "normal",
            visibility: config.price.show ? "" : "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          {product.price || config.price.example || "9,99"}
        </div>
      </Draggable>
      <Draggable
        position={{ x: config.iva.x, y: config.iva.y }}
        onStart={() => false}
      >
        <div
          name="iva"
          className={{
            box: true,
            "apply-font-iva": true,
          }}
          style={{
            fontSize: `${config.iva.font.size}px`,
            color: config.iva.font.color,
            fontWeight: config.iva.font.bold ? "bold" : "normal",
            fontStyle: config.iva.font.italic ? "italic" : "normal",
            visibility: config.iva.show ? "" : "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          {product.iva || "0"}%
        </div>
      </Draggable>
    </div>
  ) : null;
}
